'use client'

import { useEffect } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useIsConsentGroupEnabled } from '@marketplace-web/shared/consent'
import { ConsentGroup } from 'constants/consent'

import Google from './setups/Google'
import Pubstack from './setups/Pubstack'
import Amazon from './setups/Amazon'
import Prebid from './setups/Prebid'
import Yieldbird from './setups/Yieldbird'
import Confiant from './setups/Confiant'
import Rokt from './setups/Rokt'

const AdScripts = () => {
  const isYieldBirdScriptEnabled = useFeatureSwitch('web_ads_yieldbird_script')
  const isRoktScriptEnabled = useFeatureSwitch('web_ads_rokt_script')
  const isProgrammaticAdsEnabled = useFeatureSwitch('web_ads_programmatic')

  useEffect(() => {
    window.adPlacements = {}
  }, [])

  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  if (!isConsentGroupEnabled) return null

  return (
    <>
      {isProgrammaticAdsEnabled && (
        <>
          <Google />
          <Pubstack />
          <Amazon />
          <Prebid />
          {isYieldBirdScriptEnabled && <Yieldbird />}
          <Confiant />
        </>
      )}
      {isRoktScriptEnabled && <Rokt />}
    </>
  )
}

export default AdScripts
