'use client'

import Script from 'next/script'
import { useEffect } from 'react'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { ConsentGroup } from 'constants/consent'
import { useIsConsentGroupEnabled } from '@marketplace-web/shared/consent'

import { setupApsServiceScript, initApsServices } from './utils'

const Amazon = () => {
  const hasConsentedTargeting = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const isAmazonPublisherAudienceEnabled = useFeatureSwitch('web_ads_amazon_publisher_audience')

  useEffect(() => {
    if (!hasConsentedTargeting) return

    setupApsServiceScript(isAmazonPublisherAudienceEnabled)
    initApsServices()
  }, [hasConsentedTargeting, isAmazonPublisherAudienceEnabled])

  if (!hasConsentedTargeting) return null

  return (
    <Script
      id="amazon-tag"
      data-testid="amazon-tag"
      src="https://c.amazon-adsystem.com/aax2/apstag.js"
      strategy="lazyOnload"
    />
  )
}

export default Amazon
