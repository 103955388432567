'use client'

import { InputText, Text } from '@vinted/web-ui'
import { useFormContext } from 'react-hook-form'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { isEmailValid } from '@marketplace-web/domain/identity'

import { Field } from '../../../constants'
import { FormModel } from '../../../types'

type Props = {
  name: Field
} & Pick<ComponentProps<typeof InputText>, 'title' | 'placeholder' | 'required'>

const AdvertisingInputText = ({ name, title, placeholder, required }: Props) => {
  const { register, formState } = useFormContext<FormModel>()
  const translate = useTranslate('advertising.form.validation')

  return (
    <InputText
      {...register(name, {
        required,
        validate: {
          email: value => name !== Field.Email || isEmailValid(String(value)),
        },
      })}
      title={title}
      placeholder={placeholder}
      styling={InputText.Styling.Wide}
      aria={{
        'aria-invalid': !!formState.errors[name],
        'aria-errormessage': `${name}-error`,
        'aria-required': required,
      }}
      validation={
        formState.errors[name] && (
          <Text type={Text.Type.Caption} theme="warning" as="p" id={`${name}-error`}>
            {formState.errors[name]?.type === 'required'
              ? translate('required')
              : translate('invalid_email')}
          </Text>
        )
      }
    />
  )
}

export default AdvertisingInputText
