'use client'

import { useEffect, useRef } from 'react'
import Script from 'next/script'

import { useIsConsentGroupEnabled } from '@marketplace-web/shared/consent'

import { ConsentGroup } from 'constants/consent'

import { RoktLauncher, RoktLauncherPromise } from 'types/ads'

import useIsInPage from 'hooks/useIsInPage'
import { PageId } from 'types/page-id'

import { ROKT_ACCOUNT_ID } from '../../../../constants'

const isInInboxPage: Array<PageId> = ['inbox', 'inbox-message']

const Rokt = () => {
  const resolveRef = useRef<((value: RoktLauncher | RoktLauncherPromise) => void) | null>(null)

  const hasConsentedTargeting = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const hasConsentedPersonalisedAds = useIsConsentGroupEnabled(ConsentGroup.PersonalisedAds)
  const hasConsentedRokt = useIsConsentGroupEnabled(ConsentGroup.RoktAdvertising)

  const isInInbox = useIsInPage(isInInboxPage)

  const isScriptAvailable =
    isInInbox && hasConsentedTargeting && hasConsentedPersonalisedAds && hasConsentedRokt

  function getRoktLauncher() {
    return window.Rokt?.createLauncher({
      accountId: ROKT_ACCOUNT_ID,
    })
  }

  function onRoktLoad() {
    const launcher = getRoktLauncher()

    if (launcher) {
      resolveRef.current?.(launcher)
    }
  }

  useEffect(() => {
    if (!isScriptAvailable) return

    window.RoktLauncher = new Promise<RoktLauncher>(resolve => {
      resolveRef.current = resolve

      if (!window.Rokt) return

      const launcher = getRoktLauncher()

      if (launcher) resolve(launcher)
    })
  }, [isScriptAvailable])

  if (!isScriptAvailable) return null

  return (
    <Script
      crossOrigin="anonymous"
      id="rokt-launcher"
      data-testid="rokt-launcher-script"
      src="https://apps.rokt.com/wsdk/integrations/launcher.js"
      strategy="lazyOnload"
      onLoad={onRoktLoad}
    />
  )
}

export default Rokt
