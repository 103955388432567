'use client'

import { Button, Container, Image, Dialog, Spacer, Text } from '@vinted/web-ui'

import { useIsDarkMode } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { CDN_IMAGES_URL } from 'constants/index'

type Props = { handleClose: () => void } & ComponentProps<typeof Dialog>

const FormErrorModal = ({ closeOnOverlay = true, handleClose, ...props }: Props) => {
  const isDarkMode = useIsDarkMode()
  const translate = useTranslate('advertising.form.error_message')

  const iconPath = `${CDN_IMAGES_URL}/landing/advertising/cross-circle${
    isDarkMode ? '_dark' : ''
  }.svg`

  return (
    <Dialog
      testId="advertising-form-error-modal"
      closeOnOverlay={closeOnOverlay}
      defaultCallback={handleClose}
      {...props}
    >
      <Container>
        <div className="u-flexbox u-align-items-center u-flex-direction-column">
          <Image
            src={iconPath}
            size={Image.Size.Large}
            testId="advertising-form-error-modal-image"
            alt=""
          />
          <Spacer size={Spacer.Size.Large} />
          <Text
            as="h1"
            text={translate('title')}
            type={Text.Type.Heading}
            alignment={Text.Alignment.Center}
          />
        </div>
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          theme="primary"
          styling={Button.Styling.Filled}
          text={translate('close')}
          onClick={handleClose}
        />
      </Container>
    </Dialog>
  )
}

export default FormErrorModal
